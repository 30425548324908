<template>
  <div>
      <nav-bar class="title_top bg-white" :icon="'img/backb.png'" :textColor="'#0A2042'" :backColor="'#fff'" />

      <div class="padding-sm">
          <div class="rule_box padding-lr-sm">
              <div class="rule_title display_flex align-items_center margin-top-xl">
                  <img src="img/line.png" alt="">
                  <span class="margin-left-xs">如何邀请并建立邀请人关系？</span>
              </div>
              <p class="margin-top-sm">点击"邀请好友"按钮，向好友发送邀请图片，好友通过您的二维码下载后注册后，在“任务”模块中完成并领取“新人专享”任务奖励，则可视为有效邀请人</p>
              <div class="rule_title display_flex align-items_center margin-top-xl">
                  <img src="img/line.png" alt="">
                  <span class="margin-left-xs">如何查看我邀请的好友？</span>
              </div>
              <p class="margin-top-sm">1）您可前往"我的报表"中查看您邀请的好友列表；</p>
              <p class="margin-top-sm">2）您可在"我的报表"中进行用户id或昵称的关键字查询。</p>
              <div class="rule_title display_flex align-items_center margin-top-xl">
                  <img src="img/line.png" alt="">
                  <span class="margin-left-xs">为什么会绑定失败?</span>
              </div>
              <p class="margin-top-sm">1）下载安装后尽量在2小时内打开App，否则可能将超出邀请码有效期；</p>
              <p class="margin-top-sm">2）不要覆盖安装，需卸载原App后通过二维码或链接重新下载；</p>
              <p class="margin-top-sm">3）不要使用越狱设备，尽量不要使用模拟器；</p>
              <p class="margin-top-sm">4）不要在微信或QQ中直接打开，需前往手机自带浏览器中打开邀请链接；</p>
              <p class="margin-top-sm">5）不要直接发送下载的apk邀请好友安装，否则您的邀请码将会丢失。</p>
              <div class="rule_title display_flex align-items_center margin-top-xl">
                  <img src="img/line.png" alt="">
                  <span class="margin-left-xs">绑定失败后如何处理？</span>
              </div>
              <p class="margin-top-sm">当您的好友邀请关系绑定失败时，请及时携邀请记录即相关证明联系客服，在客服审核完毕后可为您进行补绑操作;</p>
              <div class="rule_title display_flex align-items_center margin-top-xl">
                  <img src="img/line.png" alt="">
                  <span class="margin-left-xs">如何获取邀请奖励？</span>
              </div>
              <p class="margin-top-sm">1）下级邀请用户赠送礼物消费时，您都可获得一定比例的奖励；</p>
              <p class="margin-top-sm">2）二级邀请用户赠送礼物消费后，您同样可获得一定比例的奖励。</p>
              <div class="rule_title display_flex align-items_center margin-top-xl">
                  <img src="img/line.png" alt="">
                  <span class="margin-left-xs">邀请奖励比例明细</span>
              </div>
              <!-- <img class="rule_table margin-top-sm" src="img/table.png" alt=""> -->
              <p class="margin-top-sm">1）奖励当前统计为金币奖励，最终需折算成现金奖励；</p>
              <p class="margin-top-sm">2）您可获得下级邀请玩家的1%的经典礼物流水收益，幸运礼物，背包礼物，人气礼物等为特殊玩法，不统计邀请收益。</p>
              <div class="rule_title display_flex align-items_center margin-top-xl">
                  <img src="img/line.png" alt="">
                  <span class="margin-left-xs">如何提现?</span>
              </div>
              <p class="margin-top-sm">1）您需完善收款人信息才可发起提现，您可前往【我的】-【钱包】-【管理】中完善收款人信息；</p>
              <p class="margin-top-sm">2）累积一定奖励金额即可发起提现；</p>
              <p class="margin-top-sm margin-bottom-xl">3）奖励提现将于2个工作日内打款至您的收款账户中。</p>
          </div>
      </div>

  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import NavBar from '@/components/navBar.vue';
export default {
  name: 'rule',
  components: {
    NavBar
  },
  data() {
    return {
        
    };
  },
  created() {
  },
  methods: {

  },
};
</script>